<template>
    <component :is="isCard ? 'b-card' : 'section'" :class="isCard ? 'position-relative p-5' : ''" no-body>
        <loading v-if="loading"/>
        <validation-observer ref="shotPatternValidationForm" tag="form">
            <section>
                <h3 class="text-left text-secondary font-weight-bolder">
                    {{ this.editing ? 'Editar' : 'Novo' }} Modelo de Disparo
                </h3>

                <div class="row">
                    <div class="col-12 col-lg-6 pt-3 pb-5">
                        <validation-provider
                            #default="{ errors }"
                            rules="required">
                            <input
                                type="text"
                                placeholder="Nome"
                                class="form-control"
                                :class="{ 'is-invalid': errors.length > 0 }"
                                v-model="form.name"
                            />
                        </validation-provider>
                    </div>
                </div>
            </section>

            <section class="text-left mb-4">
                <section>
                    <h5 class="text-dark font-weight-bolder m-0 mb-3">
                        Horários de Disparo
                    </h5>

                    <div class="d-flex flex-wrap mt-3">
                        <div class="d-flex flex-column form-group mr-4">
                            <label class="m-0">Início</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required">
                                <date-picker
                                    v-model="form.begin_date"
                                    placeholder="Selecione uma data"
                                    prefix-class="xmx"
                                    :input-class="{ 'mx-input': true, 'border-danger': errors.length > 0 }"
                                    type="datetime"
                                    format="DD/MM/YYYY HH:mm"
                                    value-type="YYYY-MM-DD HH:mm"
                                    :lang="datePickerPt"
                                    @pick="autoScroll($event)"
                                    :show-second="false"
                                    :disabled-date="(date) => disableDateTime(date, form.finish_date, 'max')"
                                    :disabled-time="(date) => disableDateTime(date, form.finish_date, 'max', true)"
                                ></date-picker>
                            </validation-provider>
                        </div>
                        <div class="d-flex flex-column form-group mr-4">
                            <label class="m-0">Final</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required">
                                <date-picker
                                    :disabled="form.begin_date === null"
                                    v-model="form.finish_date"
                                    placeholder="Selecione uma data"
                                    format="DD/MM/YYYY HH:mm"
                                    value-type="YYYY-MM-DD HH:mm"
                                    prefix-class="xmx"
                                    :show-second="false"
                                    :input-class="{ 'mx-input': true, 'border-danger': errors.length > 0 }"
                                    type="datetime"
                                    :lang="datePickerPt"
                                    @pick="autoScroll($event)"
                                    :disabled-date="(date) => disableDateTime(date, form.begin_date, 'min')"
                                    :disabled-time="(date) => disableDateTime(date, form.begin_date, 'min', true)"
                                ></date-picker>
                            </validation-provider>
                        </div>
                    </div>

                    <div class="col">
                        <div class="row">
                            <div class="d-flex flex-row text-left mr-2">
                                <b-form-checkbox v-model="form.is_custom"
                                                 :class="form.is_custom ? 'text-blue' : ''"
                                                 class="mt-4 mb-3"
                                                 name="check-button" size="lg" switch>
                                    Calendário Personalizado
                                </b-form-checkbox>
                            </div>
                            <div class="col-12">
                                <custom-calendar v-model="form.calendar"/>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </validation-observer>

        <div class="row align-items-center justify-content-end mt-5">
            <div class="col-12 col-md-4 d-flex mt-2">
                <button v-if="isCard" class="btn btn-outline-primary flex-grow-1 mr-2" @click.prevent="goToHome">Cancelar</button>
                <button class="btn flex-grow-1 btn-primary" @click.prevent="submit">Salvar</button>
            </div>
        </div>
    </component>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
    BCard,
    BFormCheckbox,
} from 'bootstrap-vue';
import { customCalendarModel, datePickerPt } from "../campaigns/constants";
import CustomCalendar from "./components/CustomCalendar.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@/@core/utils/validations/validations';
import api from '@/api';
import Loading from '@/views/components/Loading.vue';
import { disableDateTime, autoScroll } from '@/api/functions';

const shotPatternModel = {
    _id: null,
    name: null,
    begin_date: null,
    finish_date: null,
    is_custom: false,
    calendar: null
}

export default {
    components: {
        BCard,
        BFormCheckbox,
        DatePicker,
        CustomCalendar,
        ValidationProvider,
        ValidationObserver,
        Loading
    },
    watch: {
        'form.is_custom'(val) {
            if(this.loading) return;

            if(val) this.form.calendar = Object.assign({}, customCalendarModel);
            else this.form.calendar = null;
        }
    },
    props: {
        isCard: {
            type: Boolean,
            default: true
        },
    },
    emit: ['created'],
    data() {
        return {
            form: Object.assign({}, shotPatternModel),
            loading: false,
            required,
            datePickerPt
        }
    },
    created() {
        if(this.$route.name === 'shots/patterns/edit') {
            this.getShotPattern(this.$route.params.id)
        }
    },
    computed: {
        editing() {
            return this.form._id != null
        }
    },
    methods: {
        disableDateTime,
        autoScroll,
        goToHome() {
            this.$swal({
                title: "Operação cancelada",
                text: "Retornando a página de disparos",
                icon: "error",
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                this.$router.replace({name: 'shots/patterns'});
            });
        },
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Modelos de Disparo',
                autoHideDelay: 2000,
                variant: type
            })
        },
        getShotPattern(id) {
            this.loading = true;
                api.get(`/shots/patterns/${id}`)
                .then(res => {
                    const { type, body } = res.data;

                    if(type === 'success') {
                        return this.form = Object.assign({}, body);
                    }
                    throw res;
                })
                .catch(err => {
                    let message = 'Não foi possível recuperar modelo de disparo.';

                    if(err.response) {
                        message = err.response.data.message;
                    }

                    this.toast(message);
                })
                .finally(() => this.loading = false);
        },
        submit() {
            this.$refs.shotPatternValidationForm.validate().then(success => {
                if(!success) return;

                this.loading = true;
                api({
                    method: this.editing ? 'PUT' : 'POST',
                    url: this.editing ? `/shots/patterns/${this.form._id}` : `/shots/patterns`,
                    data: this.form
                })
                .then(res => {
                    const { type, message, body } = res.data;

                    if(type === 'success') {
                        this.toast(message, 'success');

                        this.$emit('created', body);

                        if(!this.editing && this.isCard)
                            return this.$router.replace({name: 'campaigns'});
                        return Object.assign(this.form, body);
                    }
                    throw res;
                })
                .catch(err => {
                    let message = 'Não foi possível salvar modelo de disparo.';

                    if(err.data) {
                        message = err.data.message;
                    }

                    this.toast(message);
                })
                .finally(() => this.loading = false);
            })
        },
    },
}
</script>
