<template>
    <section v-if="value != null">
        <div v-for="(day, i) in Object.keys(value)" :key="`calendart-row-${i}`" class="d-flex align-items-end my-2">
            <h5 
                :class="{ 'font-weight-bolder': value[day].active, 'font-weight-normal': !value[day].active}" 
                class="m-0 form-control border-0"
            >
                {{ getDay(day) }}
            </h5>
            <div class="d-flex">
                <div class="form-group m-0">
                    <label class="m-0">De</label>

                    <validation-provider
                        #default="{ failed }"
                        :disabled="!value[day].active"
                        rules="required"
                    >
                        <date-picker
                            v-model="value[day].primary.begin"
                            :disabled="!value[day].active"
                            type="time"
                            format="HH:mm"
                            value-type="format"
                            :show-second="false"
                            :input-class="{ 'mx-input': true, 'border-danger': failed && value[day].active }"
                            :disabled-time="(date) => disableTime(date, value[day].primary.finish, false)"
                        ></date-picker>
                    </validation-provider>
                </div>
                <div class="form-group m-0">
                    <label class="m-0">Às</label>
                    
                    <validation-provider
                        #default="{ failed }"
                        :disabled="!value[day].active"
                        rules="required"
                    >
                        <date-picker
                            v-model="value[day].primary.finish"
                            :disabled="!value[day].active"
                            type="time"
                            :show-second="false"
                            format="HH:mm"
                            value-type="format"
                            :input-class="{ 'mx-input': true, 'border-danger': failed && value[day].active }"
                            :disabled-time="(date) => disableTime(date, value[day].primary.begin)"
                        ></date-picker>
                    </validation-provider>
                </div>
            </div>
            <div class="d-flex justify-content-end form-control border-0">
                <div class="form-check">
                    <input
                        @input="(e) => value[day].interval = e.target.checked"
                        class="form-check-input" 
                        type="checkbox"
                        :id="`check-interval-${i}`"
                        :disabled="!value[day].active"
                        :checked="value[day].interval"
                    >
                    <label class="form-check-label" :for="`check-interval-${i}`">
                        Intervalo
                    </label>
                </div>
            </div>
            <div class="d-flex">
                <div class="form-group m-0">
                    <label class="m-0">De</label>

                    <validation-provider
                        #default="{ failed }"
                        :disabled="!value[day].interval || !value[day].active"
                        rules="required"
                    >
                        <date-picker
                            v-model="value[day].secondary.begin"
                            :disabled="!value[day].interval || !value[day].active"
                            type="time"
                            :show-second="false"
                            format="HH:mm"
                            value-type="format"
                            :input-class="{ 'mx-input': true, 'border-danger': failed  && !(!value[day].interval || !value[day].active) }"
                            :disabled-time="(date) => disableTime(date, value[day].secondary.finish, false) || disableInterval(date, value[day].primary, value[day].secondary)"
                        ></date-picker>
                    </validation-provider>
                </div>
                <div class="form-group m-0">
                    <label class="m-0">Às</label>
                    <validation-provider
                        #default="{ failed }"
                        :disabled="!value[day].interval || !value[day].active"
                        rules="required"
                    >
                        <date-picker
                            v-model="value[day].secondary.finish"
                            :disabled="!value[day].interval || !value[day].active"
                            :disabled-time="(date) => disableTime(date, value[day].secondary.begin) || disableInterval(date, value[day].primary, value[day].secondary)"
                            type="time"
                            :show-second="false"
                            format="HH:mm"
                            :input-class="{ 'mx-input': true, 'border-danger': failed && !(!value[day].interval || !value[day].active) }"
                            value-type="format"
                        ></date-picker>
                    </validation-provider>
                </div>
            </div>
            <div class="d-flex justify-content-center form-control border-0">
                <div class="form-check">
                    <input
                        @input="(e) => value[day].active = !e.target.checked"
                        class="form-check-input" 
                        type="checkbox"
                        :id="`check-off-${i}`"
                        :checked="!value[day].active"
                    >
                    <label class="form-check-label" :for="`check-off-${i}`">
                        Folga
                    </label>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { ValidationProvider } from "vee-validate";
import moment from 'moment';

const daysOfWeek = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo',
}

export default {
    components: {
        DatePicker,
        ValidationProvider
    },
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    emits: ['input'],
    methods: {
        getDay(name) {
            return daysOfWeek[name];
        },
        disableTime(min, time, isMin = true) {
            try {
                const num1 = moment(`1970-01-01 ${time}:00`);
                const num2 = moment(`1970-01-01 ${min.toLocaleTimeString()}`);
                
                return isMin ? num1 > num2 : num1 < num2;
            } catch (error) {
                return true;
            }
        },
        disableInterval(time, exclude, interval) {
            try {
                const date = moment(`1970-01-01 ${time.toLocaleTimeString()}`);
                let exclude1 = exclude.begin ? moment(`1970-01-01 ${exclude.begin}:00`) : null;
                let exclude2 = exclude.finish ? moment(`1970-01-01 ${exclude.finish}:00`) : null;
                const interval1 = interval.begin ? moment(`1970-01-01 ${interval.begin}:00`) : null;
                const interval2 = interval.finish ? moment(`1970-01-01 ${interval.finish}:00`) : null;

                if (interval1 && interval1 < exclude1) 
                    return date > exclude1;
                if (interval2 && interval2 > exclude2) 
                    return date < exclude2;
                
                return date > exclude1 && date < exclude2;
            } catch (error) {
                return true;
            }
        },
    },
    watch: {
        value: {
            handler(val) {
                this.$emit('input', val);
            },
            deep: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .mx-datepicker {
        max-width: 7rem !important;
    }
</style>