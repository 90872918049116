<template>
    <emoji-picker @emoji="(emoji) => $emit('emoji', emoji)" :search="search">
        <font-awesome-icon
            class="emoji-invoker"
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
            role="button"
            color="gray"
            icon="fa-solid fa-face-smile"
        />
        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
            <div class="emoji-picker">
                <div class="emoji-picker__search">
                    <input type="text" v-model="search" v-focus>
                </div>
                <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div class="emojis">
                            <span
                                v-for="(emoji, emojiName) in emojiGroup"
                                :key="emojiName"
                                @click="insert(emoji)"
                                :title="emojiName"
                            >{{ emoji }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'

export default {
    components: {
        EmojiPicker
    },
    data() {
        return {
            search: ''
        };
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
}
</script>